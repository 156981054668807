<template>
    <div>
        <v-container fluid class="pa-6">
            <v-row justify="center">
                <v-col cols="10" class="pt-12 text-center">
                    
                    <v-card class="bg-pink">
                        <v-card-text class="pa-0">
                        <v-row class="pa-0">
                            <v-col cols="3" class="pa-0">
                                <v-img max-height="150" contain :src="'/hand_megaphone.png'"></v-img>
                            </v-col>
                            <v-col class="d-flex align-center justify-start">
                                <span class="text-left">
                                    <h1 class="blacked text-center white--text" style="justify-content:center; font-size:24px;">La configuration de cette opération n'est pas terminée</h1>
                                    <v-btn depressed v-if="data.can_configure" @click="openOperationStepper" color="white" class="mt-4">
                                        Finir la configuration
                                        <v-icon small class="ml-2">$next</v-icon>    
                                    </v-btn>
                                    <div v-else>
                                        <span class="white--text" v-if="data && data.operation_user">
                                            {{data.operation_user.firstname}} {{data.operation_user.name}} doit finir la configuration pour que vous puissiez y accéder
                                        </span>
                                    </div>
                                </span>
                            </v-col>
                        </v-row>
                        </v-card-text>
                    </v-card>
                   
                </v-col>
                <OperationStepperDialog @closeModal="closeStepperModal" @afterConfigureBy="afterConfigureBy" @StepperFinished="StepperFinished" :displayOperationStepper="displayOperationStepper" :operationId="operationId"/>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import OperationStepperDialog from '@/modules/operations/operationsComponents/OperationStepperDialog'
import GenericDataService from '@/services/GenericDataService'
export default {
    name:"OperationConfiguration",
    components: {OperationStepperDialog},
    data() {
        return {
            operationId: null,
            data: {},
            displayOperationStepper:false
        }
    },
    created(){
        this.$store.dispatch('base/SET_SUB_MENU', [])
        this.operationId = this.$route.params.operation_id
        GenericDataService.getData("/operation/"+this.$route.params.operation_id+"/hasRightForStepper").then((response) => {
             this.data = response.data.data
        })
    },
    methods: {
        openOperationStepper(){
            this.displayOperationStepper = true
        },
        closeStepperModal(){
            this.displayOperationStepper = false
        },
        StepperFinished(){
            this.displayOperationStepper = false
            this.$router.push({name:"OperationDetail", params:{operation_id: this.operationId}})
        },
        afterConfigureBy(){
            this.displayOperationStepper = false
            this.$router.push({name:"OperationsList"})
        }
    }
}
</script>
<style lang="scss" scoped>
    .bg-pink {
        background: rgb(255,180,111);
        background: -moz-linear-gradient(118deg, rgba(255,180,111,1) 0%, rgba(255,43,135,1) 56%, rgba(255,62,187,1) 100%);
        background: -webkit-linear-gradient(118deg, rgba(255,180,111,1) 0%, rgba(255,43,135,1) 56%, rgba(255,62,187,1) 100%);
        background: linear-gradient(118deg, rgba(255,180,111,1) 0%, rgba(255,43,135,1) 56%, rgba(255,62,187,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffb46f",endColorstr="#ff3ebb",GradientType=1);
    }
    

</style>
