<template>
    <div>
        <v-container fluid class="pa-6">
            <v-row>
                <v-col xs="12" sm="12" lg="6" md="6">
                    <OperationsTypeDetailConfigurationDetails v-if="operationtype" :operationtype="operationtype" @refreshOperationtype="customRefresh" />
                    <OperationsTypeDetailConfigurationEntrypoints class="mt-6" :entrypoints="entrypoints" :operation_type_id="operation_type_id" @refreshEntryPoints="refreshEntryPoints" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import OperationsTypeDetailConfigurationDetails from '@/modules/operationstype/views/OperationsTypeDetailConfigurationDetails.vue';
import OperationsTypeDetailConfigurationEntrypoints from '@/modules/operationstype/views/OperationsTypeDetailConfigurationEntrypoints.vue';

import GenericDataService from '@/services/GenericDataService';

export default {
    name: "OperationsTypeDetailConfiguration",
    components: {OperationsTypeDetailConfigurationDetails, OperationsTypeDetailConfigurationEntrypoints},
    data() {
        return {
            operation_type_id: 0,
            operationtype: [],
            entrypoints:[],
        }
    },
    created(){
        this.operation_type_id = this.$route.params.operationtype_id;
        this.customRefresh();
    },
    methods:{
        customRefresh(){
            GenericDataService.getData('/operationtype/' + this.$route.params.operationtype_id + '/get').then((response) => {
                this.operationtype = response.data.data;
                this.entrypoints = response.data.data.entrypoints;
                this.$store.dispatch('SET_OPERATION_TYPE_NAME', this.operationtype.name);
            })
        },
        refreshEntryPoints() {
            GenericDataService.getData('/operationtype/' + this.$route.params.operationtype_id + '/getEntryPointsArray').then((response) => {
                this.entrypoints = response.data.data;
            });
        },
    }
}
</script>