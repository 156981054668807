var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"600"},model:{value:(_vm.linkedRPDialog),callback:function ($$v) {_vm.linkedRPDialog=$$v},expression:"linkedRPDialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"bg-gradient",attrs:{"tile":"","flat":"","dark":""}},[_c('v-btn',{staticClass:"ml-2",staticStyle:{"position":"absolute"},attrs:{"x-large":"","depressed":"","color":"transparent","dark":""},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$prev")]),_vm._v(" "+_vm._s(_vm.$t("back")))],1),_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('programmation')))]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pt-4"},[_c('div',{staticClass:"black--text"},[_c('b',[_vm._v(_vm._s(_vm.$t('missionToProgram')))])]),_c('v-card',{staticClass:"mt-2 mb-2",staticStyle:{"border-color":"#161459 !important"},attrs:{"outlined":"","rounded":"lg"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between py-3"},[_c('div',[(_vm.clickedRP && _vm.clickedRP.config.date_start)?_c('div',{staticClass:"dateElement mb-1"},[_vm._v(" "+_vm._s(_vm.parseFullDateToFr(_vm.clickedRP.config.date_start))+" "),(_vm.clickedRP.config.date_end)?_c('span',[_vm._v(" - "+_vm._s(_vm.parseFullDateToFr(_vm.clickedRP.config.date_end)))]):_vm._e()]):_vm._e(),(_vm.clickedRP)?_c('h5',{staticClass:"pb-0"},[_vm._v(_vm._s(_vm.clickedRP.config.name))]):_vm._e()]),(_vm.clickedRP && _vm.clickedRP.config.hasOwnProperty('coach_selected'))?_c('div',_vm._l((_vm.clickedRP.config.coach_selected),function(coach,index){return _c('v-tooltip',{key:'coach_'+coach.value+'_'+_vm.clickedRP.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"50"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.api+'/user/'+coach.value+'/getAvatar?token='+_vm.$store.state.auth.idToken+'&nocache=true'}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(coach.text))])])}),1):(_vm.clickedRP && _vm.clickedRP.config.hasOwnProperty('coach_selection'))?_c('div',_vm._l((_vm.clickedRP.config.coach_selection),function(coach,index){return _c('v-tooltip',{key:'coach_'+coach.value+'_'+_vm.clickedRP.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"size":"50"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.api+'/user/'+coach.value+'/getAvatar?token='+_vm.$store.state.auth.idToken+'&nocache=true'}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(coach.text))])])}),1):(_vm.linkedRP && _vm.linkedRP[0] && _vm.linkedRP[0].config.hasOwnProperty('coach_selection'))?_c('div',_vm._l((_vm.linkedRP[0].config.coach_selection),function(coach,index){return _c('v-tooltip',{key:'coach_'+coach.value+'_'+_vm.linkedRP[0].id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"size":"50"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.api+'/user/'+coach.value+'/getAvatar?token='+_vm.$store.state.auth.idToken+'&nocache=true'}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(coach.text))])])}),1):(_vm.linkedRP && _vm.linkedRP[0] && _vm.linkedRP[0].config.hasOwnProperty('coach_selected'))?_c('div',_vm._l((_vm.linkedRP[0].config.coach_selection),function(coach,index){return _c('v-tooltip',{key:'coach_'+coach.value+'_'+_vm.linkedRP[0].id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"size":"50"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.api+'/user/'+coach.value+'/getAvatar?token='+_vm.$store.state.auth.idToken+'&nocache=true'}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(coach.text))])])}),1):_vm._e()])],1),(_vm.linkedRP.length > 0)?_c('div',{staticClass:"mt-4 black--text"},[_c('b',[_vm._v(_vm._s(_vm.$t('missionLinkedthatwillbeprogramtoo')))])]):_vm._e(),_vm._l((_vm.linkedRP),function(rp,index){return _c('v-card',{key:rp.id,staticClass:"mb-2 mt-2",staticStyle:{"border-color":"#161459 !important"},attrs:{"outlined":"","rounded":"lg"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between py-3"},[_c('div',[(rp.config.date_start)?_c('div',{staticClass:"dateElement mb-1"},[_vm._v(" "+_vm._s(_vm.parseFullDateToFr(rp.config.date_start))+" "),(rp.config.date_end)?_c('span',[_vm._v(" - "+_vm._s(_vm.parseFullDateToFr(rp.config.date_end)))]):_vm._e()]):_vm._e(),_c('h5',{staticClass:"pb-0"},[_vm._v(_vm._s(rp.config.name))])]),(rp.config.hasOwnProperty('coach_selected'))?_c('div',_vm._l((rp.config.coach_selected),function(coach,index){return _c('v-tooltip',{key:'coach_'+coach.value+'_'+rp.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"50"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.api+'/user/'+coach.value+'/getAvatar?token='+_vm.$store.state.auth.idToken+'&nocache=true'}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(coach.text))])])}),1):(rp.config.hasOwnProperty('coach_selection'))?_c('div',_vm._l((rp.config.coach_selection),function(coach,index){return _c('v-tooltip',{key:'coach_'+coach.value+'_'+rp.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"size":"50"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.api+'/user/'+coach.value+'/getAvatar?token='+_vm.$store.state.auth.idToken+'&nocache=true'}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(coach.text))])])}),1):(_vm.clickedRP.config.hasOwnProperty('coach_selection'))?_c('div',_vm._l((_vm.clickedRP.config.coach_selection),function(coach,index){return _c('v-tooltip',{key:'coach_'+coach.value+'_'+rp.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"size":"50"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.api+'/user/'+coach.value+'/getAvatar?token='+_vm.$store.state.auth.idToken+'&nocache=true'}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(coach.text))])])}),1):(_vm.clickedRP.config.hasOwnProperty('coach_selected'))?_c('div',_vm._l((_vm.clickedRP.config.coach_selection),function(coach,index){return _c('v-tooltip',{key:'coach_'+coach.value+'_'+rp.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"size":"50"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":_vm.api+'/user/'+coach.value+'/getAvatar?token='+_vm.$store.state.auth.idToken+'&nocache=true'}})],1)]}}],null,true)},[_c('span',{attrs:{"depressed":""}},[_vm._v(_vm._s(coach.text))])])}),1):_vm._e()])],1)}),_c('div',{staticClass:"text-right mt-5"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","dark":"","color":"grey"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),(_vm.linkedRP.length > 0)?_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","dark":"","color":"pink"},on:{"click":_vm.dissociateAndProgram}},[_vm._v(_vm._s(_vm.$t('dissociateAndProgram')))]):_vm._e(),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"primary"},on:{"click":_vm.programAll}},[_vm._v(_vm._s(_vm.$t('continue')))])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }