<template>
    <div>
        <div class="d-flex py-2">
            <span class="h2">1 - {{ $t('contacts') }}</span>
        </div>
        <div v-if="statsContacts">
            <v-row dense>
                <v-col>
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <v-icon large>$users_d</v-icon>
                        <h3 class="mt-4">{{statsContacts.targetedContacts}}</h3>
                        <span class="label mt-3">{{ $t('targetedContacts') }}</span>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <v-icon large>$user_plus_d</v-icon>
                        <h3 class="mt-4">{{statsContacts.generatedContacts}}</h3>
                        <span class="label mt-3">{{ $t('generatedContacts') }}</span>
                    </v-card>
                </v-col>
                <v-col></v-col>
                <v-col></v-col>
                <v-col></v-col>
            </v-row>
            <v-row dense>
                <v-col cols="3">
                    <donutContactStatsComponent v-for="(graph, index) in donutGraphs" :data="graph" :filters="false" :key="'donutgraph_' + index" class="mb-2" :ref="'graph_' + graph.label" />
                </v-col>
                <v-col cols="9">
                    <v-simple-table class="mb-2">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">{{ $t('contactlistContact_count') }} ...</th>
                                    <th class="text-left">{{ $t('quantity') }}</th>
                                    <th class="text-left">{{ $t('rate') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ $t('withAtLeastOneMobilePhone') }}</td>
                                    <td>{{ statsContacts.mobilePhone }}</td>
                                    <td>{{ statsContacts.allContacts ? ((100 * statsContacts.mobilePhone) / statsContacts.allContacts).toFixed(1) : 0 }}%</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('withAtLeastOnePhone') }}</td>
                                    <td>{{ statsContacts.phone }}</td>
                                    <td>{{ statsContacts.allContacts ? ((100 * statsContacts.phone) / statsContacts.allContacts).toFixed(1) : 0 }}%</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('withoutAnyPhoneNumber') }}</td>
                                    <td>{{ statsContacts.noPhone }}</td>
                                    <td>{{ statsContacts.allContacts ? ((100 * statsContacts.noPhone) / statsContacts.allContacts).toFixed(1) : 0 }}%</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('withAtLeastOneEmail') }}</td>
                                    <td>{{ statsContacts.validEmailAddresses }}</td>
                                    <td>{{ statsContacts.allContacts ? ((100 * statsContacts.validEmailAddresses) / statsContacts.allContacts).toFixed(1) : 0 }}%</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('withCompletePostalAddress') }}</td>
                                    <td>{{ statsContacts.postalAddress }}</td>
                                    <td>{{ statsContacts.allContacts ? ((100 * statsContacts.postalAddress) / statsContacts.allContacts).toFixed(1) : 0 }}%</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <barContactStatsComponent v-for="(graph, index) in barGraphs" v-if="graph.datas" :data="graph" :filters="false" class="mb-2" :key="'bargraph_' + index" />
                </v-col>
            </v-row>
        </div>
        <div style="min-height: 500px !important;" v-else>
            <Loader :flat="true" style="min-height: 500px !important;"></Loader>
        </div>
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import barContactStatsComponent from '@/components/dialogs/dialogStatsComponents/barContactStatsComponent';
import donutContactStatsComponent from '@/components/dialogs/dialogStatsComponents/donutContactStatsComponent';
import Loader from '@/components/Loader.vue';

export default {
    name: "OperationsDetailStats",
    components: { barContactStatsComponent, donutContactStatsComponent, Loader },
    data() {
        return {
            statsContacts: null,
            donutGraphs: [
                {
                    "label": "source",
                    "datas": {}
                },
                {
                    "label": "distance",
                    "datas": {}
                }
            ],
            barGraphs: [
                {
                    "type": "BarComponent",
                    "label": "numberOfYearsSinceDateOfPurchase",
                    "datasetLabel": "contactlistContact_count",
                    "backgroundColor": "#5F05FF",
                    "datas": {}
                }
            ]
        }
    },
    created() {
        GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/getFullStatsContacts').then((response) => {
            this.statsContacts = response.data.data;

            for (let source in this.statsContacts.sourceCreate) {
                this.statsContacts.sourceCreate[source].notFilterable = true;
                this.statsContacts.sourceCreate[source].to_trad = true;
            }

            for (let distance in this.statsContacts.distance)
                this.statsContacts.distance[distance].notFilterable = true;

            for (let year in this.statsContacts.numberOfYearsSinceDateOfPurchase)
                this.statsContacts.numberOfYearsSinceDateOfPurchase[year].notFilterable = true;

            let defaultDatas = [{label: "NC (%)", notFilterable: true, value: 100}];
            this.donutGraphs[0].datas = Object.keys(this.statsContacts.sourceCreate).length ? this.statsContacts.sourceCreate : defaultDatas;
            this.donutGraphs[1].datas = Object.keys(this.statsContacts.distance).length ? this.statsContacts.distance : defaultDatas;
            this.barGraphs[0].datas = this.statsContacts.numberOfYearsSinceDateOfPurchase;
        });
    },
}
</script>

<style lang="scss">
    .elementStat {
        width: 100%;
        text-align: center;
        h3 {
            color: inherit !important;
            font-weight: 800;
            font-size: 30px;
            @media(-webkit-device-pixel-ratio: 1.25) {
                font-size: 30px;
                margin-top: 10px;
            }
            span {
                font-size: 15px;
                font-weight: 400;
            }
        }
        .label {
            display: block;
            font-size: 13px;
        }
    }
</style>