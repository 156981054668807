<template>
    <div>
        <v-card rounded="lg"  class="pa-3 pt-0 "  id="opeDashboard">
            <v-card-title class="px-3 pt-4 pb-4">
                <h2 style="text-transform: none; font-size:22px;">{{$t('operationEntryPoints')}}</h2>
                <v-spacer></v-spacer>
                <v-menu offset-y left attach="#opeDashboard" v-if="$func.hasRight('entrypoint/add')">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" @click.stop="" depressed :class="'bg-gradient'">
                            <v-icon left dark small>$plus_l</v-icon>{{ $t('add') }}
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item link v-for="(ep_type, index) in entrypoint_type" :key="'entrypoint_'+index" dense @click="openForm('create', createUrl + ep_type, ep_type)">
                            <v-list-item-title>{{ $t(ep_type) }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-title>
            <v-card-text v-if="entrypoints.length > 0">
                <div v-for="(entrypoint, index) in entrypoints" :key="entrypoint.id"  :class="!entrypoint.config.importData ? 'entryPoint' : ''">
                    <template v-if="!entrypoint.config.importData" >
                        <v-menu
                        v-if="entryPointsStats && entryPointsStats[entrypoint.id]"
                        offset-y
                        center
                        open-on-hover
                        :close-on-content-click="false"
                        content-class="no-shadow testMenu"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-card class="no-shadow " rounded="lg" v-bind="attrs" v-on="on"  @click="$func.hasRight('entrypoint/update') ? openForm('edit', editUrl + entrypoint.id) : ''">
                                    <v-card-text class="d-flex justify-space-between" style="padding:15px; flex-direction: row; width:100%; cursor:pointer;" :id="'entrypoint_'+entrypoint.id">
                                        <div class="d-flex align-center">
                                            <v-btn class="no-shadow mr-2" depressed fab small color="lightgrey">
                                                <v-icon small color="primary" v-if="entrypoint.type == 'entryLanding' && entrypoint.config.subdomain && entrypoint.config.domain">$window_r</v-icon>
                                                <v-icon small color="primary" v-if="entrypoint.type == 'entryCallIn'">$phone_d</v-icon>
                                                <v-icon small color="primary" v-if="entrypoint.type == 'entryApi'">$displaycode_s</v-icon>
                                            </v-btn>
                                            <div v-if="entrypoint.type == 'entryLanding' && entrypoint.config.subdomain && entrypoint.config.domain">{{entrypoint.config.subdomain + '.' + entrypoint.config.domain}}</div>
                                            <div v-if="entrypoint.type == 'entryCallIn'">
                                                <div v-if="entrypoint.config.number">{{phoneNumberToClean(entrypoint.config.number)}}</div>
                                                <div v-if="entrypoint.config.entity && entrypoint.config.entity.text">{{entrypoint.config.entity.text}}</div>
                                            </div>
                                            <div v-if="entrypoint.type == 'entryApi' && entrypoint.config.uniqId">
                                                <transition name="fade">
                                                    <div v-if="show[entrypoint.id]" class="copyDiv">
                                                        <v-icon color="success" class="mr-2">$copy_d</v-icon>
                                                        <h5 class="success--text" style="font-size:16px;">{{ $t('keyCopied') }}</h5>
                                                    </div>
                                                </transition>
                                                <div v-if="entrypoint.config.name">{{entrypoint.config.name}}</div>
                                                <div>{{entrypoint.config.uniqId}}</div>
                                                <input type="hidden" v-on:focus="$event.target.select()" :ref="'idApi_'+entrypoint.id" :value="entrypoint.config.uniqId" readonly>
                                            </div>
                                        </div>
                                        <div class="ml-3">
                                            <v-menu offset-y :close-on-content-click="false" :attach="'#entrypoint_'+entrypoint.id">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" @click.stop=""  class="square float-right" depressed text>
                                                        <v-icon >$dots_l</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item  v-if="entrypoint.type == 'entryLanding'" link dense @click.stop="" :href="'https://' + entrypoint.config.subdomain + '.' + entrypoint.config.domain+'?operation_id='+operationId" target="_blank">
                                                        <v-list-item-title><v-icon dark color="primary" class="mr-2" small>$browser_d</v-icon>{{ $t('visitLanding') }}</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-if="entrypoint.type == 'entryLanding' && $func.hasRight('entrypoint/update')" dense @click.stop="changeLandingDialog(entrypoint)">
                                                        <v-list-item-title><v-icon color="primary" class="mr-2" dark small >$switch_d</v-icon>{{ $t('changeModel') }}</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click.stop="openLandingDialog(entrypoint)" link dense v-if="entrypoint.type == 'entryLanding' && $func.hasRight('entrypoint/update')">
                                                        <v-list-item-title><v-icon color="primary" class="mr-2" dark small >$pencil_paintbrush_d</v-icon>{{ $t('modifyTemplate') }}</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item  v-if="$func.hasRight('entrypoint/update')" link dense @click.stop="openForm('edit', editUrl + entrypoint.id)" >
                                                        <v-list-item-title><v-icon color="primary" class="mr-2" dark small>$pencil_d</v-icon>{{ $t('edit') }}</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item  v-if="entrypoint.type == 'entryApi'" link dense @click.stop="copyApiKey('idApi_'+entrypoint.id, entrypoint.id)">
                                                        <v-list-item-title><v-icon dark color="primary" class="mr-2" small>$copy_d</v-icon>{{ $t('copyApikey') }}</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item  v-if="$func.hasRight('retroplanning/get')" link dense @click.stop="getLink(entrypoint)">
                                                        <v-list-item-title><v-icon dark color="primary" class="mr-2" small>$eye_d</v-icon>{{ $t('visitcampin') }}</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item  v-if="$func.hasRight('entrypoint/delete')" link dense @click.stop="openForm('delete', deleteUrl + entrypoint.id)">
                                                        <v-list-item-title><v-icon dark color="error" class="mr-2" small>$trashalt_d</v-icon>{{ $t('delete') }}</v-list-item-title>
                                                    </v-list-item>
                                                    
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </template>
                            <v-card rounded="lg" class="no-shadow">
                                <v-card-text>
                                    <div class="text-center">
                                        <h5 class="primary--text mb-2 pb-0" style="font-size:28px; font-weight:700;">{{ entryPointsStats[entrypoint.id]['generatedLead'] }}</h5>
                                        <b class="black--text">{{$t('leadGenerated')}}</b>
                                    </div>
                                    <div class="mt-2">
                                        <div class="d-flex justify-space-between">
                                        <span class="substat">{{ $t('knownLead') }} ({{ entryPointsStats[entrypoint.id]['knownLead'] }})</span>
                                        <span class="substatPercent">{{getPercentage(entryPointsStats[entrypoint.id]['knownLead'], entryPointsStats[entrypoint.id]['generatedLead'])}}%</span>
                                        </div>
                                        <v-progress-linear
                                            color="info"
                                            rounded
                                            :value="getPercentage(entryPointsStats[entrypoint.id]['knownLead'], entryPointsStats[entrypoint.id]['generatedLead'])"
                                            ></v-progress-linear>
                                    </div>
                                    <div class="mt-2">
                                        <div class="d-flex justify-space-between">
                                        <span class="substat">{{ $t('newLead') }} ({{ entryPointsStats[entrypoint.id]['newLead'] }})</span>
                                        <span class="substatPercent">{{getPercentage(entryPointsStats[entrypoint.id]['newLead'], entryPointsStats[entrypoint.id]['generatedLead'])}}%</span>
                                        </div>
                                        <v-progress-linear
                                            color="info"
                                            rounded
                                            :value="getPercentage(entryPointsStats[entrypoint.id]['newLead'], entryPointsStats[entrypoint.id]['generatedLead'])"
                                            ></v-progress-linear>
                                    </div>
                                    <div class="mt-3 text-center" v-if="entryPointsStats[entrypoint.id]['generatedLead'] > 0">
                                        <v-btn depressed small @click="exportContacts(entrypoint.id)" color="primary">
                                            <v-icon small class="mr-2" text>
                                                $download_s
                                            </v-icon>
                                            <span style="font-size:14px;">{{ $t('download') }}</span>
                                        </v-btn>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                        <v-card v-else class="no-shadow rounded-lg " @click="$func.hasRight('entrypoint/update') ? openForm('edit', editUrl + entrypoint.id) : ''">
                            <v-card-title class="d-flex justify-space-between" style="padding:15px; flex-direction: row; width:100%; cursor:pointer;">
                                <div class="d-flex align-center">
                                    <v-btn class="no-shadow mr-2" depressed fab small color="lightgrey">
                                        <v-icon small color="primary" v-if="entrypoint.type == 'entryLanding' && entrypoint.config.subdomain && entrypoint.config.domain">$window_r</v-icon>
                                        <v-icon small color="primary" v-if="entrypoint.type == 'entryCallIn'">$phone_d</v-icon>
                                        <v-icon small color="primary" v-if="entrypoint.type == 'entryApi'">$displaycode_s</v-icon>
                                    </v-btn>
                                    <div v-if="entrypoint.type == 'entryLanding' && entrypoint.config.subdomain && entrypoint.config.domain">{{entrypoint.config.subdomain + '.' + entrypoint.config.domain}}</div>
                                    <div v-if="entrypoint.type == 'entryCallIn' && entrypoint.config.number">{{phoneNumberToClean(entrypoint.config.number)}}</div>
                                    <div v-if="entrypoint.type == 'entryApi' && entrypoint.config.uniqId">
                                        <transition name="fade">
                                            <div v-if="show[entrypoint.id]" class="copyDiv">
                                                <v-icon color="success" class="mr-2">$copy_d</v-icon>
                                                <h5 class="success--text" style="font-size:16px;">{{ $t('keyCopied') }}</h5>
                                            </div>
                                        </transition>
                                        <div v-if="entrypoint.config.name">{{entrypoint.config.name}}</div>
                                        <div>{{entrypoint.config.uniqId}}</div>
                                        <input type="hidden" v-on:focus="$event.target.select()" :ref="'idApi_'+entrypoint.id" :value="entrypoint.config.uniqId" readonly>
                                    </div>
                                </div>
                                <div class="ml-3">
                                    <v-menu offset-y left >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" @click.stop=""  class="square float-right" depressed text>
                                                <v-icon >$dots_l</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item  v-if="entrypoint.type == 'entryLanding'" link dense :href="'https://' + entrypoint.config.subdomain + '.' + entrypoint.config.domain+'?operation_id='+operationId" target="_blank">
                                                <v-list-item-title><v-icon dark color="primary" class="mr-2" small>$eye_d</v-icon>{{ $t('visitLanding') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-if="entrypoint.type == 'entryLanding' && $func.hasRight('entrypoint/update')" dense @click.stop="changeLandingDialog(entrypoint)">
                                                <v-list-item-title><v-icon color="primary" class="mr-2" dark small >$switch_d</v-icon>{{ $t('changeModel') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click.stop="openLandingDialog(entrypoint)" link dense v-if="entrypoint.type == 'entryLanding' && $func.hasRight('entrypoint/update')">
                                                <v-list-item-title><v-icon color="primary" class="mr-2" dark small >$pencil_paintbrush_d</v-icon>{{ $t('modifyTemplate') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item  v-if="$func.hasRight('entrypoint/update')" link dense @click="openForm('edit', editUrl + entrypoint.id)" >
                                                <v-list-item-title><v-icon color="primary" class="mr-2" dark small>$pencil_d</v-icon>{{ $t('edit') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item  v-if="entrypoint.type == 'entryApi'" link dense @click="copyApiKey('idApi_'+entrypoint.id, entrypoint.id)">
                                                <v-list-item-title><v-icon dark color="primary" class="mr-2" small>$copy_d</v-icon>{{ $t('copyApikey') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item  v-if="$func.hasRight('retroplanning/get')" link dense @click.stop="getLink(entrypoint)">
                                                <v-list-item-title><v-icon dark color="primary" class="mr-2" small>$eye_d</v-icon>{{ $t('visitcampin') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item  v-if="$func.hasRight('entrypoint/delete')" link dense @click="openForm('delete', deleteUrl + entrypoint.id)">
                                                <v-list-item-title><v-icon dark color="error" class="mr-2" small>$trashalt_d</v-icon>{{ $t('delete') }}</v-list-item-title>
                                            </v-list-item>
                                            
                                        </v-list>
                                    </v-menu>
                                </div>
                            </v-card-title>
                        </v-card>
                        <v-tooltip bottom v-if="entrypoint.config.unbooked">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                color="error"
                                class="mr-2"
                                small
                                dark
                                v-bind="attrs"
                                v-on="on"
                                >
                                $delete
                                </v-icon>
                            </template>
                            <span>{{ $t('entrypointUnbooked') }}</span>
                        </v-tooltip>
                    </template>
                    
                    
                </div>
            </v-card-text>
            <v-card-text class="px-3" v-else>
                <v-alert border="left" class="mt-0 mb-0" text dense color="info">
                    <template v-slot:prepend>
                        <v-icon color="info" class="mr-3" small>
                            $warning_s
                        </v-icon>
                    </template>
                    {{$t('noEntryPointsFound')}}
                </v-alert>
            </v-card-text>
        </v-card>
        <v-dialog persistent width="400" v-model="showDownload">
            <v-card>
                <v-card-text class="pa-6">
                    <h5 class="icon-title">
                    {{$t('downloadReady')}}
                    <v-btn @click="showDownload = false" text class="square">
                        <v-icon small>$close</v-icon>
                    </v-btn>
                    </h5>
                    <div class="text-center mt-4 mb-2">
                    <a :href="downloadUrl" @click="" download class="exportBtn">{{$t('clickForDownload')}}</a>
                    </div>
                    
                </v-card-text>
            </v-card>
        </v-dialog>
        <landingPageEditor @reloadData="reloadDataLP" :dialogEditorLP="dialogEditorLP" :idLanding="idLanding" :css="css" :currentLanding="landing" :entrypoint="entrypoint" @closeDialog="closeDialog" :key="'lp_'+dialogKey"/>
        <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="libType" @cancel="formLib = null;" @customRefresh="customRefresh" @selectModel="changeLandingModel" :libParams="{operation_id : $route.params.operation_id, entrypoint_id: entrypoint.id}" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" />
        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
    </div>
</template>

<script>
import getForm from "@/mixins/mixins";
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import landingPageEditor from '@/modules/landingpage/views/landingPageEditor'
import GenericDataService from '@/services/GenericDataService'
import dateManipulation from '@/mixins/dateManipulation'

export default {
    name:"entryPointsComponent",
    mixins: [getForm, dateManipulation],
    props:['entrypoints', 'operationId', 'entryPointsStats', 'operation'],
    components:{ 
        FormDialogComponent, 
        landingPageEditor,
        LibraryDialogComponent: () => import("@/components/dialogs/LibraryDialogComponent"),
    },
    data() {
        return {
            form: null,
            api: process.env.VUE_APP_API_URL,
            formUrl: '',
            formType: '',
            createUrl: '/operation/' + this.operationId + '/getFormEntryPoints?type=',
            editUrl: '/operation/' + this.operationId + '/getFormEntryPoints?id=',
            deleteUrl: '/operation/' + this.operationId + '/checkBeforeDeleteEntryPoint?id=',
            //LP
            dialogEditorLP:false,
            idLanding:0,
            css:{},
            landing:{},
            entrypoint:{},
            entrypoint_type:[],
            libType: "LANDINGPAGE",
            formLib: null,
            showLibraryDialog: false,
            dialogKey:0,
            showApi:false,
            show: {},
            downloadUrl: "",
            showDownload: false
        }
    },
    created() {
        GenericDataService.getData('/operation/getEntryPointsTypes').then((response) => {
            this.entrypoint_type = response.data.data
        })
        this.entrypoints.forEach(element => {
            this.show[element.id] = false;
        });
    },
    watch: {
        entrypoints: function (val) {
            val.forEach(element => {
                this.show[element.id] = false;
            });
        }
    },
    methods: {
        getLink(entrypoint){
            let rpCampIn = this.operation.retroplanning.filter((e) => e.type == "retroplanning.CAMP_IN.campIN")
            let entrypoint_id = entrypoint.id
            let entrypoint_type = entrypoint.type.replace('entry', 'entrypoints')
            let rp = null
            if(entrypoint_id && entrypoint_type)
                rp = rpCampIn.find((e) => {if(e.config[entrypoint_type] && e.config[entrypoint_type].length > 0 && e.config[entrypoint_type][0].value == entrypoint_id){ return true }else{ return false}})   

            if(rp)
                this.$router.push({name:"OperationCampaignDetail", params: {operation_id: this.operation.id, id: rp.id}});
            else 
                console.log("no retroplanning found");
        },
        exportContacts(entrypoint_id){
            GenericDataService.postData('/export/exportContactsFromEp?entrypoint_id=' + entrypoint_id, {contact_ids: this.entryPointsStats[entrypoint_id]['contactIds'], operation_id: this.operation.id}).then((response) => {
                if(response.data.data.directDownload){
                    this.downloadUrl = this.api + response.data.data.directDownload;
                    this.showDownload = true;
                }
            })
        },  
        getPercentage(substat, global){
            if(global == 0)
                return 0;

            let percent = ((substat * 100)/global).toFixed(2);
            if(percent == 0)
                return 0;
            else return percent

        },
        copyApiKey(apiRef, id){
            this.show[id] = true
            this.$set(this.show, id, true)
            this.$refs[apiRef][0].focus();
            document.execCommand('copy');
            navigator.clipboard.writeText(this.$refs[apiRef][0].value)
            setTimeout(() => {
                this.show[id] = false
                this.$set(this.show, id, false)
            }, 1000);
        },
        openLandingDialog(entrypoint){
            if(!entrypoint.config.lp_setting){
                GenericDataService.getData('/landingpage/getLandingStyleOperation?operation_id='+this.operationId+'&url='+entrypoint.config.subdomain+"."+entrypoint.config.domain).then((response) => {
                    this.idLanding = entrypoint.config.landingpage.id
                    this.entrypoint = entrypoint;
                    this.css = response.data.data.css
                    this.landing = response.data.data.landing
                    if((this.landing.settings && this.landing.settings.date_end && this.landing.settings.date_end == "") || !this.landing.settings.date_end){
                        delete this.landing.hooks.lp_countdown
                    }
                    this.dialogEditorLP = true
                })
            }
            // this.idLanding = idLanding

        },
        reloadDataLP(entrypoint_id){
            let entryp = this.entrypoints.find((e) => e.id == entrypoint_id);
            if(!entryp.config.lp_setting){
                GenericDataService.getData('/landingpage/getLandingStyleOperation?operation_id='+this.operationId+'&url='+entryp.config.subdomain+"."+entryp.config.domain).then((response) => {
                    this.idLanding = entryp.config.landingpage.id
                    this.entrypoint = entryp;
                    this.css = response.data.data.css
                    this.landing = response.data.data.landing
                    if((this.landing.settings && this.landing.settings.date_end && this.landing.settings.date_end == "") || !this.landing.settings.date_end){
                        delete this.landing.hooks.lp_countdown
                    }
                    //this.dialogKey++
                })
            }
        },
        closeDialog(){
            this.dialogEditorLP = false
        },
        openForm(formType, formUrl, type) {
            //console.log(formType, formUrl, type)
            if(type == 'entryLanding'){
                GenericDataService.getData("/library/getFilters?libType=" + this.libType +'&params[operation_id]=' + this.operationId).then((response) => {
                    this.formLib = response.data.data;
                    this.showLibraryDialog = true;
                    //this.libType = btn.openLibraryDialog;
                    //this.$emit("hideGlobalAddDialog");
                });
            } else {
                this.formType = formType;
                this.formUrl = formUrl;
                this.getForm(formUrl);
            }
        },
        changeLandingDialog(entrypoint) {
            GenericDataService.getData("/library/getFilters?libType=LANDINGPAGE&params[operation_id]=" + this.operationId).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
                this.entrypoint = entrypoint;
            });
        },
        changeLandingModel(model) {
            let url = "/operation/" + this.operationId + "/setOpObject?parentField=entrypoints&type=entryLanding&model_id=" + model.id + "&id=" + this.entrypoint.id;
            GenericDataService.postData(url, this.entrypoint.config).then((response) => {
                this.customRefresh();
                this.displayDatatableAlert(response);
            });
        },
        customRefresh() {
            this.$emit('refreshEntryPoints');
        },
    },
}
</script>

<style lang="scss">
    .entryPoint {
        .v-card {
            width:100%;
            &:hover {
                background-color: rgba(0,0,0,0.05) !important;
            }
        }
        border: 1px solid #CCC;
        display:flex;
        padding:0px 0;
        border-radius:8px;
        justify-content: flex-start;
        margin-top:0;
        margin-bottom:8px;
        align-items: center;
        //overflow:hidden;
        .entryCallIn {
            display:flex;
            align-items: center;
        }
        .copyDiv {
            display:flex;
            align-items: center;
            justify-content: center;
            position:absolute;
            background-color:rgba(255,255,255,0.8);
            width:100%;
            height:100%;
            top:0;
            left:0;
        }
        .fade-enter-active {
            transition: all .1s ease;
        }
        .fade-leave-active {
            transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        }
        .fade-enter, .fade-leave-to
            /* .slide-fade-leave-active below version 2.1.8 */ {
            //transform: translateX(10px);
            opacity: 0;
        }
    }
    
</style>
