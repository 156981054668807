<template>
    <v-container fluid class="pa-6 opeTypeRetroplanning">
        <v-row v-if="$func.hasRight('operationtype/update')">
            <v-col>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small depressed color="primary" class="" v-bind="attrs" v-on="on"><v-icon small class="mr-2">$plus_l</v-icon>  {{$t('addStep')}}</v-btn>
                    </template>
                    <v-list v-for="(subTypes, type) in actions" :key="type">
                        <div class="pa-2 pl-4 listTitle">{{ $t(type) }}</div>
                        <v-list-item v-for="(subType) in subTypes" :key="subType" link dense @click="openForm(type, subType)">
                            <v-list-item-title>{{ $t(subType) }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4" v-for="(retroplanning, index) in operationTypeRetroplanning" :key="index">
                <v-card>
                    <v-card-title>
                        <h2 class="icon-title mb-0" style="min-height:unset; align-items:center; width:100%;">
                            <div>
                                {{retroplanning.config.name}}
                                <v-chip v-if="retroplanning.config.reference" class="ml-3" color="pink" dark small>{{$t('referenceStep')}}</v-chip>
                            </div>
                            <div class="icons-retro">
                                <v-btn text class="square" small v-if="$func.hasRight('operationtype/update')" @click="editItem(retroplanning.id)">
                                    <v-icon small>$pencil_l</v-icon>
                                </v-btn>
                                <v-btn v-if="!retroplanning.config.reference && $func.hasRight('operationtype/update')" text class="square" small @click="openConfirm(retroplanning.id)">
                                    <v-icon small>$trash_l</v-icon>
                                </v-btn>
                                <v-menu offset-y v-else-if="retroplanning.config.reference && $func.hasRight('operationtype/update')">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn text class="square" small v-bind="attrs" v-on="on"><v-icon small>$redo_l</v-icon></v-btn>
                                    </template>
                                    <v-list v-for="(subTypes, type) in actions" :key="type">
                                        <div class="pa-2 pl-4 listTitle">{{ $t(type) }}</div>
                                        <v-list-item v-for="(subType) in subTypes" :key="subType" link dense @click="openForm(type, subType, retroplanning.id)">
                                            <v-list-item-title>{{ $t(subType) }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </h2>
                    </v-card-title>
                    <v-card-text>
                        <p class="mb-0" v-if="retroplanning.config.canal"><b>{{$t('canal')}} : </b>{{ $t(retroplanning.config.canal) }}</p>
                        <p class="mb-0" v-if="retroplanning.config.compute_date_start">
                            <b>{{$t('start')}} : </b>
                            <span v-if="retroplanning.config.compute_date_start.placement != 'equals'">
                                <span v-if="retroplanning.config.compute_date_start.type == 'date'">{{retroplanning.config.compute_date_start.days}} {{$t('day_s')}} </span>
                                <span v-if="retroplanning.config.compute_date_start.type == 'time'">{{getChipsTime(retroplanning.config.compute_date_start.seconds, true)}} </span>
                                {{$t(retroplanning.config.compute_date_start.placement).toLowerCase()}} {{$t((operationtype.type === 'MARKETING' ? 'ref_step_' : 'operation_')+retroplanning.config.compute_date_start.reference).toLowerCase()}} 
                            </span>
                            <span v-else>
                                {{$t(retroplanning.config.compute_date_start.placement)}} {{$t((operationtype.type === 'MARKETING' ? 'ref_step_' : 'operation_')+retroplanning.config.compute_date_start.reference).toLowerCase()}} 
                            </span>
                            <span v-if="retroplanning.config.compute_date_start.placement != 'equals'">
                                <span v-if="retroplanning.config.compute_date_start.type == 'date'">{{$t('at_time').toLowerCase()}} {{retroplanning.config.compute_date_start.time}} </span>
                            </span>
                            <span v-if="retroplanning.config.compute_date_start.with_off_days">({{$t('with_off_days')}})</span>
                        </p>
                        <p class="mb-0" v-if="retroplanning.config.compute_date_end && retroplanning.config.compute_date_end.placement">
                            <b>{{$t('end')}} : </b>
                            <span v-if="retroplanning.config.compute_date_end.placement != 'equals'">
                                <span v-if="retroplanning.config.compute_date_end.type == 'date'">{{retroplanning.config.compute_date_end.days}} {{$t('day_s')}} </span>
                                <span v-if="retroplanning.config.compute_date_end.type == 'time'">{{getChipsTime(retroplanning.config.compute_date_end.seconds, true)}} </span>
                                {{$t(retroplanning.config.compute_date_end.placement).toLowerCase()}} {{$t((operationtype.type === 'MARKETING' ? 'ref_step_' : 'operation_')+retroplanning.config.compute_date_end.reference).toLowerCase()}} 
                            </span>
                            <span v-else>
                                {{$t(retroplanning.config.compute_date_end.placement)}} {{$t((operationtype.type === 'MARKETING' ? 'ref_step_' : 'operation_')+retroplanning.config.compute_date_end.reference).toLowerCase()}} 
                            </span>
                            <span v-if="retroplanning.config.compute_date_end.placement != 'equals'">
                                <span v-if="retroplanning.config.compute_date_end.type == 'date'">{{$t('at_time').toLowerCase()}} {{retroplanning.config.compute_date_end.time}} </span>
                            </span>
                            <span v-if="retroplanning.config.compute_date_end.with_off_days">({{$t('with_off_days')}})</span>
                        </p>
                        <p class="mb-0" v-if="retroplanning.config.prestataire && retroplanning.config.prestataire.deadline_mission"><b>{{$t('deadline_for_submission_to_provider')}} :</b> {{retroplanning.config.prestataire.deadline_mission}} {{$t('day_s')}}</p>
                        <p class="mb-0" v-if="retroplanning.config.sending_vol"><b>{{$t('sending_vol')}} :</b> {{retroplanning.config.sending_vol}}</p>
                        <p v-if="retroplanning.config.segmentation" class="mb-0">
                            <b>{{$t('segmentation')}} : </b>
                            <span v-if="retroplanning.config.segmentation.name">{{retroplanning.config.segmentation.name}}</span>
                            <span v-else>{{$t(retroplanning.config.segmentation)}}</span>
                        </p>
                        <p class="mb-0" v-if="retroplanning.config.staff_qty"><b>{{$t('staff_qty')}} :</b> {{retroplanning.config.staff_qty}}</p>
                        <p class="mb-0" v-if="retroplanning.config.prestataire_type && retroplanning.config.prestataire_type === 'VENDORS'"><b>{{$t('prestataire')}} :</b> {{$t('vendors')}}</p>
                        <p class="mb-0" v-if="retroplanning.config.prestataire"><b>{{$t('prestataire')}} :</b> {{retroplanning.config.prestataire.text}}</p>
                        <p class="mb-0" v-if="retroplanning.config.infomediaire"><b>{{$t('prestataire')}} :</b> {{retroplanning.config.infomediaire.text}}</p>
                        <p class="mb-0" v-if="retroplanning.config.template_cat"><b>{{$t('template_cat')}} :</b> {{retroplanning.config.template_cat.text}}</p>
                        <p class="mb-0" v-if="retroplanning.config.managing_type"><b>{{$t('managing_type')}} :</b> {{$t(retroplanning.config.managing_type)}}</p>
                        <p class="mb-0" v-if="retroplanning.config.description"><b>{{$t('description')}} :</b> {{(retroplanning.config.description)}}</p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog width="600" v-model="dialogConfirm">
                <v-card>
                    <v-toolbar flat dark color="primary" class="toolbar" :style="'background-image:url('+publicPath+'img/blue-background.jpg); background-size:cover; height:63px;'">
                        <v-toolbar-title>{{$t('confirmDeleteLabel')}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn depressed outlined color="white" class="mr-3" @click="closeDialog">
                            {{$t('cancelLabel')}}
                        </v-btn>
                        <v-btn depressed outlined color="white" @click="deleteItem">
                            {{$t('confirmLabel')}}
                        </v-btn>
                    </v-toolbar>
                    <v-card-text class="pt-3">
                        {{$t('confirmDeleteRetroplanningStepText')}}
                    </v-card-text>
                </v-card>
            </v-dialog>
             <FormDialogComponent
                v-if="form"
                ref="componentForm"
                :form="form"
                :action="'SET_DATA'"
                />
        </v-row>
    </v-container>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import getChipsTime from '@/mixins/dateManipulation'
import getForm from "@/mixins/mixins";
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
export default {
    name:"OperationsTypeDetailRetroplanning",
    components: {FormDialogComponent},
    mixins: [getChipsTime, getForm],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            operationtype: {},
            segmentName: "",
            operationTypeRetroplanning: [],
            actions: [],
            form:null,
            dialogConfirm:false,
            idToDelete:0
        }
    },
    created() {
        this.customRefresh()
    },
    methods: {
        editItem(itemId){
            this.getForm('/operationtype/' + this.$route.params.operationtype_id + '/getFormRetroplanning?id=' + itemId);
        },
        openForm(type, subType, replaceRefId = null){
            let url = '/operationtype/' + this.$route.params.operationtype_id + '/getFormRetroplanning?type=' + type + "&subType=" + subType;

            if(replaceRefId)
                url += '&replaceRefId=' + replaceRefId;

            this.getForm(url);
        },
        customRefresh(){
            GenericDataService.getData('/operationtype/'+this.$route.params.operationtype_id+'/get').then((response) => {
                this.operationtype = response.data.data
                this.operationTypeRetroplanning = response.data.data.retroplanning.filter((e) => !e.config.workflow_id)
                if(this.operationtype.config.segment_id && this.operationtype.config.segment_id != ''){
                    GenericDataService.getData('/segment/'+this.operationtype.config.segment_id+'/get').then((response) => {
                        this.segmentName = response.data.data.name
                    })
                }
                GenericDataService.getData("/operation/getRetroplanningTypes").then((response) => {
                    this.actions = response.data.data;
                })
            })
        },
        openConfirm(itemId){
            this.idToDelete = itemId
            this.dialogConfirm = true
        },
        closeDialog(){
            this.idToDelete = 0
            this.dialogConfirm = false
        },
        deleteItem(){
            GenericDataService.getData('/operationtype/'+this.$route.params.operationtype_id+'/deleteRetroplanning?id='+this.idToDelete).then((response) => {
                this.operationTypeRetroplanning = response.data.data.filter((e) => !e.config.workflow_id)
                this.idToDelete = 0
                this.dialogConfirm = false
            })
        },
    },
}
</script>
<style lang="scss">
    .listTitle {
        text-transform: uppercase;
        border-bottom:1px solid rgba(0,0,0,0.2);
    }
</style>