<template>
    <div>
        <div class="d-flex py-2">
            <span class="h2">2 - {{ $t('leadGeneration') }}</span>
            <v-spacer></v-spacer>
            <div>
                <v-select v-if="operation.type === 'MARKETING'" background-color="white" v-model="periodModel" :items="periodItems" :label="$t('selectDateRange')" dense outlined hide-details clearable></v-select>
            </div>
        </div>
        <div v-if="statsLeadGeneration">
            <v-row dense>
                <v-col cols="3">
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <v-icon large>$user_plus_d</v-icon>
                        <h3 class="mt-4">{{statsLeadGeneration.TOTAL}}</h3>
                        <span class="label mt-3">{{ $t('leads') }}</span>
                    </v-card>
                </v-col>
                <v-col cols="3" v-if="$func.hasRight('retroplanning/displayprice')">
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <v-icon large>$sackdollar_d</v-icon>
                        <h3 class="mt-4">{{statsLeadGeneration.OPECOST}} €</h3>
                        <span class="label mt-3">{{ $t('spentBudget') }}</span>
                    </v-card>
                </v-col>
                <v-col cols="3" v-if="$func.hasRight('retroplanning/displayprice')">
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <v-icon large>$handholdingusd_d</v-icon>
                        <h3 class="mt-4">{{statsLeadGeneration.TOTAL ? (statsLeadGeneration.OPECOST / statsLeadGeneration.TOTAL).toFixed(0) : 0}} €</h3>
                        <span class="label mt-3">{{ $t('leadCost') }}</span>
                    </v-card>
                </v-col>
                <v-col></v-col>
            </v-row>
            <v-row dense>
                <v-col cols="3">
                    <donutContactStatsComponent v-for="(graph, index) in donutGraphs" :data="graph" :filters="false" :key="'donutgraph_' + index + '_' + donutKey" class="mb-2" :ref="'graph_' + graph.label" />
                </v-col>
                <v-col cols="9">
                    <v-simple-table class="mb-2">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">{{ $t('campaign') }}</th>
                                    <th class="text-left">{{ $t('canal') }}</th>
                                    <th class="text-left">{{ $t('generatedLeads') }}</th>
                                    <th class="text-left" v-if="$func.hasRight('retroplanning/displayprice')">{{ $t('budget') }} (€)</th>
                                    <th class="text-left" v-if="$func.hasRight('retroplanning/displayprice')">{{ $t('leadCost') }} (€)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in statsLeadGeneration.byRetroplanning" :key="item.name">
                                    <td>{{ item.label }}</td>
                                    <td>{{ $t(item.canal) }}</td>
                                    <td>{{ item.leads }}</td>
                                    <td v-if="$func.hasRight('retroplanning/displayprice')">{{ item.budget }}</td>
                                    <td v-if="$func.hasRight('retroplanning/displayprice')">{{ item.costByLead }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
        <div style="min-height: 500px !important;" v-else>
            <Loader :flat="true" style="min-height: 500px !important;"></Loader>
        </div>
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import donutContactStatsComponent from '@/components/dialogs/dialogStatsComponents/donutContactStatsComponent';
import Loader from '@/components/Loader.vue';

export default {
    name: "OperationsDetailStats",
    props: ['operation'],
    components: { donutContactStatsComponent, Loader },
    data() {
        return {
            statsLeadGeneration: null,
            periodModel: null,
            periodItems: [
                { "value": "nextNinetyDays", "text": this.$t("nextNinetyDays") },
                { "value": "endMonth", "text": this.$t("endMonth") },
                { "value": "today", "text": this.$t("today") },
                { "value": "yesterday", "text": this.$t("yesterday") },
                { "value": "thisWeekFromMonday", "text": this.$t("thisWeekFromMonday") },
                { "value": "lastSevenDays", "text": this.$t("lastSevenDays") },
                { "value": "lastWeek", "text": this.$t("lastWeek") },
                { "value": "lastFourteenDays", "text": this.$t("lastFourteenDays") },
                { "value": "thisMonth", "text": this.$t("thisMonth") },
                { "value": "lastMonth", "text": this.$t("lastMonth") },
                { "value": "lastThirtyDays", "text": this.$t("lastThirtyDays") },
                { "value": "lastNinetyDays", "text": this.$t("lastNinetyDays") },
                { "value": "lastOneEightyDays", "text": this.$t("lastOneEightyDays") },
                { "value": "thisYear", "text": this.$t("thisYear") },
                { "value": "slidingYear", "text": this.$t("slidingYear") }
            ],
            donutGraphs: [
                {
                    "label": "generatedLeadsByCanal",
                    "datas": {}
                }
            ],
            donutKey: 0
        }
    },
    created() {
        this.loadStats();
    },
    methods: {
        loadStats() {
            let periodUrl = this.periodModel ? ('?period=' + this.periodModel) : '';
            GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/getFullStatsLeadGeneration' + periodUrl).then((response) => {
                this.statsLeadGeneration = response.data.data;
                
                for (let createCanal in this.statsLeadGeneration.byCreateCanal)
                    this.statsLeadGeneration.byCreateCanal[createCanal] = { "value": this.statsLeadGeneration.byCreateCanal[createCanal], "label": createCanal, "to_trad": true, "notFilterable": true };

                let defaultDatas = [{label: "NC (%)", notFilterable: true, value: 100}];
                this.donutGraphs[0].datas = Object.keys(this.statsLeadGeneration.byCreateCanal).length ? this.statsLeadGeneration.byCreateCanal : defaultDatas;
                this.donutKey++;
            });
        }
    },
    watch: {
        periodModel () {
            this.loadStats();
        },
    },
}
</script>

<style lang="scss">
    .elementStat {
        width: 100%;
        text-align: center;
        h3 {
            color: inherit !important;
            font-weight: 800;
            font-size: 30px;
            @media(-webkit-device-pixel-ratio: 1.25) {
                font-size: 30px;
                margin-top: 10px;
            }
            span {
                font-size: 15px;
                font-weight: 400;
            }
        }
        .label {
            display: block;
            font-size: 13px;
        }
    }
</style>