<template>
    <div>
        <v-card rounded="lg"  class="pa-3 pt-0 "  id="opeDashboard">
            <v-card-title class="px-3 pt-2 pb-4">
                <h2>{{$t('operationtypeEntryPoints')}}</h2>
                <v-spacer></v-spacer>
                <v-menu offset-y left attach="#opeDashboard" v-if="$func.hasRight('operationtype/update')">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" @click.stop="" text depressed class="square">
                            <v-icon dark small>$plus_l</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item link v-for="(ep_type, index) in entrypoint_type" :key="'entrypoint_'+index" dense @click="openForm('create', createUrl + ep_type)">
                            <v-list-item-title>{{ $t(ep_type) }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-title>
            <v-card-text v-if="entrypoints.length > 0">
                <div v-for="entrypoint in entrypoints" :key="entrypoint.id" class="entryPoint py-2">
                    <v-tooltip bottom v-if="entrypoint.config.unbooked">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                            color="error"
                            class="mr-2"
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                            >
                            $delete
                            </v-icon>
                        </template>
                        <span>{{ $t('entrypointUnbooked') }}</span>
                    </v-tooltip>
                    <div class="dataEntryPoint">
                        <div style="font-weight:bold; flex-shrink:0; min-width:30%;">
                            {{$t(entrypoint.type)}} 
                            <span v-if="entrypoint.config.name">({{entrypoint.config.name}})</span>
                            <span v-if="entrypoint.type == 'entryLanding' && entrypoint.config.subdomain && entrypoint.config.domain">(<a :href="'https://' + entrypoint.config.subdomain + '.' + entrypoint.config.domain" target="blank">{{entrypoint.config.subdomain + '.' + entrypoint.config.domain}}</a>)</span>
                            <span v-if="entrypoint.type == 'entryEmail' && entrypoint.config.subdomain && entrypoint.config.domain">({{entrypoint.config.subdomain + '@' + entrypoint.config.domain}})</span>
                             :
                        </div>
                        <div class="ml-3">
                            <span v-if="entrypoint.config.who_manage_new_leads && entrypoint.config.who_manage_new_leads === 'call'">{{$t('who_manage_new_leads')}} : {{entrypoint.config.call.text}} <br/></span>
                            <span v-if="entrypoint.config.who_manage_new_leads && entrypoint.config.who_manage_new_leads === 'vendor'">{{$t('who_manage_new_leads')}} : {{$t('vendor')}} <br/></span>
                            <span v-if="entrypoint.type == 'entryCallIn' && entrypoint.config.district">{{$t('entryCallInDistrictTitle')}} : {{entrypoint.config.district}} <br/></span>
                            <span v-if="entrypoint.config.redirect_number && entrypoint.config.redirect_number === 'call'">{{$t('entryCallInRedirect_numberTitle')}} : {{entrypoint.config.call.text}} <br/></span>
                            <span v-if="entrypoint.config.redirect_number && entrypoint.config.redirect_number === 'entityNumber'">{{$t('entryCallInRedirect_numberTitle')}} : {{$t('entityNumber')}} <br/></span>
                            <span v-if="entrypoint.config.template">{{$t('call_script')}} : {{entrypoint.config.template.name}} <br/></span>
                            <span v-if="entrypoint.type == 'entryApi' && entrypoint.config.uniqId">{{$t('uniqId')}} : {{entrypoint.config.uniqId}}</span>
                        </div>
                    </div>
                    <v-spacer></v-spacer>
                    <span v-if="$func.hasRight('operationtype/update')">
                        <v-btn @click="openForm('edit', editUrl + entrypoint.id)" text depressed class="square">
                            <v-icon dark small>$pencil_d</v-icon>
                        </v-btn>
                        <v-btn @click="openForm('delete', deleteUrl + entrypoint.id)" text depressed class="square">
                            <v-icon dark color="error" small>$trashalt_d</v-icon>
                        </v-btn>
                    </span>
                </div>
            </v-card-text>
            <v-card-text class="px-3" v-else>
                <v-alert border="left" class="mt-0 mb-0" text dense color="info">
                    <template v-slot:prepend>
                        <v-icon color="info" class="mr-3" small>
                            $warning_s
                        </v-icon>
                    </template>
                    {{$t('noEntryPointsFound')}}
                </v-alert>
            </v-card-text>
        </v-card>
        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
    </div>
</template>

<script>
import getForm from "@/mixins/mixins";
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import GenericDataService from '@/services/GenericDataService'

export default {
    name:"entryPointsComponent",
    mixins: [getForm],
    props:['entrypoints', 'operation_type_id'],
    components:{ FormDialogComponent },
    data() {
        return {
            form: null,
            formUrl: '',
            formType: '',
            createUrl: '/operationtype/' + this.operation_type_id + '/getFormEntryPoints?type=',
            editUrl: '/operationtype/' + this.operation_type_id + '/getFormEntryPoints?id=',
            deleteUrl: '/operationtype/' + this.operation_type_id + '/checkBeforeDeleteEntryPoint?id=',
            entrypoint:{},
            entrypoint_type:[],
        }
    },
    created() {
        GenericDataService.getData('/operation/getEntryPointsTypes').then((response) => {
            this.entrypoint_type = response.data.data
        })
    },
    methods: {
        openForm(formType, formUrl) {
            this.formType = formType;
            this.formUrl = formUrl;
            this.getForm(formUrl);
        },
        customRefresh() {
            this.$emit('refreshEntryPoints');
        },
    },
}
</script>

<style lang="scss">
    .entryPoint {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        display:flex;
        justify-content: flex-start;
        align-items: center;
        .entryCallIn {
            display:flex;
            align-items: center;
        }
    }
</style>
