<template>
    <v-card depressed rounded="lg" elevation="1" class="configurationCard">
        <div class="pa-6 pt-2 configurationContainer">
            <div class="headingIndicators">
                <h2 class="primary--text mb-3">
                    {{$t('details')}}
                </h2>
            </div>

            <div class="block mt-6">
                <div class="mt-5" v-if="operationtype.name">
                    <div>{{$t('operationName')}} : <b>{{operationtype.name}}</b></div>
                    <div>{{$t('operationType')}} : <b>{{$t(operationtype.type)}}</b></div>
                </div>
                <div class="mb-3" v-if="operationtype.name">
                    <div>{{$t('type')}} : <b>{{$t(operationtype.type.toLowerCase())}}</b></div>
                </div>

                <div v-if="operationtype.user_entity_id">
                    <div>{{$t('entity')}} : <b class="link"><router-link :to="{name: 'EntityDetail', params: {entity_id: operationtype.user_entity_id.id}}">{{operationtype.user_entity_id.label}}</router-link></b></div>
                </div>
                <div class="mb-3" v-if="operationtype.segment_id">
                    <div>{{$t('segment')}} : <b>{{operationtype.segment_id.label}}</b></div>
                </div>

                <div class="mb-3" v-if="operationtype.config && (typeof(operationtype.config.configSBS) !== 'undefined')">
                    <div>{{$t('sbs_config')}} : <b>{{operationtype.config.configSBS ? $t('yes') : $t('no')}}</b></div>
                </div>

                <div v-if="operationtype.config && (typeof(operationtype.config.slot_duration) !== 'undefined')">
                    <div>{{$t('slot_duration')}} : <b>{{operationtype.config.slot_duration}} {{$t('minutes_minify')}}</b></div>
                </div>
                <div v-if="operationtype.config && (typeof(operationtype.config.required_fields) !== 'undefined')">
                    <div>{{$t('required_fields')}} : <b>{{arrayString(operationtype.config.required_fields)}}</b></div>
                </div>
                <div class="mb-3" v-if="operationtype.config && (typeof(operationtype.config.with_passage) !== 'undefined')">
                    <div>{{$t('passage_list')}} : <b>{{operationtype.config.with_passage ? $t('yes') : $t('no')}}</b></div>
                </div>

                <div v-if="operationtype.config && (typeof(operationtype.config.with_stock) !== 'undefined')">
                    <div>{{$t('with_stock')}} : <b>{{operationtype.config.with_stock ? $t('yes') : $t('no')}}</b></div>
                </div>
                <div v-if="operationtype.config && (typeof(operationtype.config.with_media) !== 'undefined')">
                    <div>{{$t('with_media')}} : <b>{{operationtype.config.with_media ? $t('yes') : $t('no')}}</b></div>
                </div>

                <div class="mt-6">
                    <v-btn depressed color="primary" @click="editForm()">{{$t('edit')}}</v-btn>
                </div>
            </div>
        </div>
        
        <FormDialogComponent v-if="form" ref="componentForm" :type="'edit'" :form="form" :action="'SET_DATA'" />
    </v-card>
</template>

<script>
//import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from '@/mixins/mixins';

export default {
    name:"OperationsTypeDetailConfigurationDetails",
    components: {  },
    mixins:[getForm],
    props:['operationtype'],
    beforeCreate() {
        this.$options.components.FormDialogComponent = require('@/components/dialogs/FormDialogComponent.vue').default
    },
    data() {
        return {
            form: null,
        }
    },
    methods: {
        editForm() {
            let formName = 'full';
            switch(this.operationtype.type) {
                case 'EVENT':
                    formName = 'typeEvent'
                    break;
                case 'MARKETING':
                    formName = 'typeMarketing'
                    break;
            }

            this.getForm('/operationtype/' + this.operationtype.id + '/getForm?formName=' + formName);
        },
        customRefresh() {
            this.$emit('refreshOperationtype');
        },
        arrayString(array) {
            var string = array.map(function(item) {
                return item.text ? this.$t(item.text) : item;
            }, this);

            return string.join(', ');
        }
    },
}
</script>
