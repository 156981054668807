<template>
    <v-card depressed rounded="lg" elevation="1" class="configurationCard">
        <div class="pa-6 pt-2 configurationContainer">
            <div class="headingIndicators">
                <h2 class="primary--text mb-3">{{$t('details')}}</h2>
            </div>

            <div class="block mt-6">
                <div  v-if="operation.config && operation.type !== 'MARKETING' && operation.config.date_start && operation.config.date_end">
                        <v-icon small color="primary">$rocket_launch_d</v-icon>
                        <v-chip small class="ml-3 mr-6" color="secondary" label outlined>{{parseFullDateToFr(operation.config.date_start)}}</v-chip>

                        <v-icon small color="primary">$flag_checkered_d</v-icon>
                        <v-chip small class="ml-3" color="secondary" label outlined>{{parseFullDateToFr(operation.config.date_end)}}</v-chip>
                </div>

                <div class="mt-5" v-if="operation.name">
                    <div>{{$t('operationName')}} : <b>{{operation.name}}</b></div>
                </div>
                <div class="mb-3" v-if="operation.reference">
                    <div>{{$t('reference')}} : <b>{{operation.reference}}</b></div>
                </div>
                <div class="mb-3" v-if="operation.config && operation.config.hasOwnProperty('modele_name')">
                    <div>{{$t('operationType')}} : <b>{{operation.config.modele_name}}</b></div>
                </div>

                <div v-if="operation.config && operation['config.brand_ids']">
                    <div>{{$t('brands')}} : <b class="mr-2" :to="{name: 'BrandDetail', params: {brand_id: brand.id}}" v-for="(brand, index ) in operation['config.brand_ids']" :key="brand.id">{{brand.label}}<span v-if="operation['config.brand_ids'].length > 1 && index < (operation['config.brand_ids'].length - 1)">,</span></b></div>
                </div>
                <div v-if="operation.config && operation['config.univers_ids']">
                    <div>{{$t('univers')}} : <b class="mr-2" v-for="(univers, index) in operation['config.univers_ids']" :key="univers.id">{{univers.label}}<span v-if="operation['config.univers_ids'].length > 1 && index < (operation['config.univers_ids'].length - 1)">,</span></b></div>
                </div>
                <div v-if="entities">
                    <div>{{$t('entities')}} : 
                        <span v-if="this.$func.hasRight('entity/get')">
                            <router-link class="mr-2" :to="{name: 'EntityDetail', params: {entity_id: entity.id}}" v-for="entity in entities" :key="entity.id"><b class="link">{{entity.social_raison}}</b></router-link>
                        </span>
                        <b v-else v-for="entity in entities" :key="entity.id">{{entity.social_raison}}</b>
                    </div>
                </div>
                <div class="pb-2" v-if="operation.config && operation.config.segment_id">
                    <div>{{$t('segment')}} :<b>{{operation['config.segment_id'].label}}</b></div>
                </div>
                <div class="mt-2" v-if="operation.created_by && operation.created_by.id">
                    <div>{{$t('createdBy')}} :
                        <router-link v-if="!operation.created_by.trash && this.$func.hasRight('user/get')" :to="{ name: 'UserDetail', params: { user_id: operation.created_by.id }}"><b class="link">{{ operation.created_by.firstname }} {{ operation.created_by.name }}</b></router-link>
                        <v-tooltip v-else-if="operation.created_by.trash" top>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ operation.created_by.firstname }} {{ operation.created_by.name }}
                                </span>
                            </template>
                            <span>{{ $t('userDeleted') }}</span>
                        </v-tooltip>
                        <b v-else>{{ operation.created_by.firstname }} {{ operation.created_by.name }}</b>
                        <span v-if="operation.created_at"> {{$t('the')}} {{parseFullDateToFr(operation.created_at)}}</span>
                    </div>
                </div>
                <div v-if="operation.validated_by && operation.validated_by.id">
                    <div>{{$t('validatedBy')}} :
                        <router-link v-if="!operation.validated_by.trash && this.$func.hasRight('user/get')" :to="{ name: 'UserDetail', params: { user_id: operation.validated_by.id }}"><b class="link">{{ operation.validated_by.firstname }} {{ operation.validated_by.name }}</b></router-link>
                        <v-tooltip v-else-if="operation.validated_by.trash" top>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ operation.validated_by.firstname }} {{ operation.validated_by.name }}
                                </span>
                            </template>
                            <span>{{ $t('userDeleted') }}</span>
                        </v-tooltip>
                        <b v-else>{{ operation.validated_by.firstname }} {{ operation.validated_by.name }}</b>
                        <span v-if="operation.validated_date"> {{$t('the')}} {{parseFullDateToFr(operation.validated_date)}}</span>
                    </div>
                </div>
                <div class="mt-4" v-if="operation.config && operation.config.comment">
                    {{$t('comment')}} :<p class="mb-0"><b>{{operation.config.comment}}</b></p>
                </div>
                <div v-if="operation.config && operation.config.files && operation.config.files.length > 0" class="attachementDiv mt-4">
                    {{$t('attachements')}} :
                    <div v-for="attachement in operation.config.files" :key="attachement.id" @click="downloadItem(attachement.url)" download outlined color="primary" >
                        <v-icon x-small color="lightgrey" class="mr-2">$circle_s</v-icon>
                        <span class="link" v-if="attachement.title">{{attachement.title}}</span>
                        <span class="link" v-else>{{attachement.filename.split('/').pop()}}</span>
                    </div>

                </div>
                
                <div class="mt-6">
                    <v-btn depressed color="primary" @click="getForm(editUrl)" v-if="$func.hasRight('operation/update')">{{$t('edit')}}</v-btn>
                </div>
            </div>
        </div>
        <FormDialogComponent v-if="form" ref="componentForm" :type="'edit'" :form="form" :action="'SET_DATA'" @launchFunction="launchFunction"/>
    </v-card>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation';
import GenericDataService from '@/services/GenericDataService';
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from '@/mixins/mixins';

export default {
    name:"configurationComponent",
    components: { FormDialogComponent },
    mixins:[dateManipulation, getForm],
    props:['values', 'operation'],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            configDialog: false,
            entities:[],
            operationType:{},
            form: null,
            editUrl: "operation/getForm?formName=edit&id=" + this.$route.params.operation_id,
        }
    },
    created() {
        this.entities = []
        this.getEntities()
    },
    mounted() {
        this.entities = []
        this.getEntities()
    },
    watch: {
        operation: {
            deep:true,
            handler(){
                this.getEntities()
            }
        }
    },
    methods: {
        launchFunction($event){
            this[$event]();
        },
        downloadItem(url){
            window.open(url)
        },
        remapOpeTypeConf(){
            GenericDataService.getData('/operation/remapOpeType?operation_id='+this.$route.params.operation_id).then((response) => {
                this.$emit('refreshOperation');
                this.$store.dispatch('ADD_SNACK', {
                    message : this.$t('successActionConfirmed'),
                }).then((e) => {
                    this.$store.dispatch("REMOVE_SNACK")
                });
            });
        },
        getEntities() {
            if(this.operation && this.operation.config && this.operation.config.entity_id && this.operation.config.entity_id != ""){
                this.operation.config.entity_id.forEach(entityId => {
                    if(this.$store.state.auth.currentUser.rights[0].entities.includes(entityId)){
                        GenericDataService.getData('/entity/'+entityId+'/get').then((response) => {
                            let findEntity = this.entities.find((e) => e.id == response.data.data.id)
                            if(!findEntity){
                                this.entities.push(response.data.data)
                            }
                        })
                    }
                   
                });
            }
        },
        customRefresh() {
            this.$emit('refreshOperation');
        },
    },
}
</script>
<style lang="scss">
    .startEnd{
        display:flex;
    }
</style>
