<template>
    
    <v-card rounded="lg" :class="'pa-3 pt-0'" :key="'workflow_'+keyWF">
        <v-card-title :class="'px-3 pt-4 pb-4'" style="display:flex; justify-content:space-between;">
            <h2 style="text-transform: none; font-size:22px;">{{$t('workflows')}}</h2>
            <v-btn @click="openLibraryDialog" depressed :class="'bg-gradient'" v-if="$func.hasRight('workflow/add')">
                <v-icon left dark small>$plus_l</v-icon>{{ $t('add') }}
            </v-btn>
        </v-card-title>
        <v-card-text :class="'px-3 cardLink'">
            <template v-for="(workflow, index) in workflows" >
                <v-menu
                :key="index"
                offset-y
                center
                open-on-hover
                link
                :close-on-content-click="false"
                content-class="no-shadow testMenu"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-card rounded="lg" :class="' no-shadow '" v-bind="attrs" v-on="on" @click="$func.hasRight('workflow/update') ? gotoWorkflow(workflow) : ''">
                        <v-card-text class="cardOpe">
                            <b>{{workflow.name}}</b>
                            <div class="d-flex align-center" :id="'workflow_'+workflow.id">
                            <v-chip small class="mx-2" v-if="workflow.tobeconfig && workflow.tobeconfig == '1'" style="flex-shrink:0;" :color="'error'">{{$t('tobeconfigured') }}</v-chip>
                            <v-chip small class="mx-2" v-else style="flex-shrink:0;" :color="workflow.active ? 'success' : 'error'">{{ workflow.active ? $t('activated') : $t('notactivated') }}</v-chip>
                            <v-menu offset-y left :close-on-content-click="false" :min-width="200" :attach="'#workflow_'+workflow.id">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" @click.stop=""  class="square float-right" depressed text>
                                        <v-icon >$dots_l</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <template v-for="(action, index) in actions">
                                        <v-list-item small :disabled="(action.title == 'workflowActivate' && workflow.tobeconfig && workflow.tobeconfig == '1') ? true : false" depressed text v-if="(action.hasOwnProperty('depends') ? (workflow[action.depends.field] == action.depends.value) : true)"  @click.stop="launchAction(action, workflow)" 
                                            :key="'action_'+index+'_'+workflow.id" 
                                            v-show="(action.security && action.security[0]) ? $func.hasRight(action.security[0]) : true">
                                            <v-icon small class="mr-2" :color="(action.title == 'workflowActivate' && workflow.tobeconfig) ? 'grey' : 'primary'">${{action.icon}}</v-icon>{{ $t(action.title) }}
                                        </v-list-item>
                                    </template>
                                    
                                </v-list>
                            </v-menu>
                            </div>
                        </v-card-text>
                    </v-card>
                </template>
                <v-card rounded="lg" class="no-shadow">
                    <v-card-text>
                        <div class="text-center">
                            <h5 class="primary--text mb-2 pb-0" style="font-size:28px; font-weight:700;">{{ workflow.total_inscription }}</h5>
                            <b class="black--text">{{$t('totalInscriptionWorkflow')}}</b>
                        </div>
                        <div class="mt-2">
                            <div class="d-flex justify-space-between">
                               <span class="substat">{{ $t('currentInscription') }} ({{ workflow.current_inscription }})</span>
                               <span class="substatPercent">{{getPercentage(workflow.current_inscription, workflow.total_inscription)}}%</span>
                            </div>
                            <v-progress-linear
                                color="info"
                                rounded
                                :value="getPercentage(workflow.current_inscription, workflow.total_inscription)"
                                ></v-progress-linear>
                        </div>
                        <div class="mt-2">
                            <div class="d-flex justify-space-between">
                               <span class="substat">{{ $t('doneInscription') }} ({{ (workflow.total_inscription - workflow.current_inscription) }})</span>
                               <span class="substatPercent">{{getPercentage((workflow.total_inscription - workflow.current_inscription), workflow.total_inscription)}}%</span>
                            </div>
                            <v-progress-linear
                                color="info"
                                rounded
                                :value="getPercentage((workflow.total_inscription - workflow.current_inscription), workflow.total_inscription)"
                                ></v-progress-linear>
                        </div>
                        <div class="mt-3 text-center" v-if="workflow.total_inscription > 0">
                            <v-btn depressed small @click="exportContacts(workflow.id)" color="primary">
                                <v-icon small class="mr-2" text>
                                    $download_s
                                </v-icon>
                                <span style="font-size:14px;">{{ $t('download') }}</span>
                            </v-btn>
                            
                        </div>
                    </v-card-text>
                </v-card>
            </v-menu>
            </template>
            
            <v-alert v-if="workflows.length == 0" class="mb-0 mb-2" border="left" color="info" text>
                {{ $t("NoWorkflowOpeInfoMessage") }}
            </v-alert>
        </v-card-text>
        <v-dialog persistent width="400" v-model="showDownload">
            <v-card>
                <v-card-text class="pa-6">
                    <h5 class="icon-title">
                    {{$t('downloadReady')}}
                    <v-btn @click="showDownload = false" text class="square">
                        <v-icon small>$close</v-icon>
                    </v-btn>
                    </h5>
                    <div class="text-center mt-4 mb-2">
                        <a :href="downloadUrl" @click="" download class="exportBtn">{{$t('clickForDownload')}}</a>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <LibraryDialogComponent 
                v-if="formLib" :form="formLib" 
                :libType="libType" @cancel="formLib = null;" 
                :libParams="{operation_id : operationId}"
                :showLibraryDialog="showLibraryDialog" 
                @hideLibraryDialog="showLibraryDialog = false;" 
                @customRefresh="customRefresh"
                @cancelFormDialogComponent="customRefresh" />
        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
        <ConfirmDialogComponent ref="confirmDelete" />
    </v-card>
</template>
<script>
import Loader from '@/components/Loader'
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import GenericDataService from '@/services/GenericDataService';
import getForm from "@/mixins/mixins";
import dateManipulation from '@/mixins/dateManipulation'
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";
import ConfirmDialogComponent from '@/components/dialogs/ConfirmDialogComponent'

export default {
    name:"workflowOperationComponent",
    components: {FormDialogComponent, Loader, LibraryDialogComponent, ConfirmDialogComponent,
        FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"),},
    mixins: [getForm, dateManipulation],
    props: ["operationId"],
    data() {
        return {
            form:null,
            publicPath: process.env.BASE_URL,
            api: process.env.VUE_APP_API_URL,
            dialogConfirm:false,
            workflows: [],
            dialogTitle: "addPlace",
            form:null,
            itemToDelete: null,
            loading:false,
            steps: [],
            formLib: null,
            libType: "WORKFLOW",
            showLibraryDialog: false,
            actions:[],
            keyWF:0,
            downloadUrl: "",
            showDownload: false
        }
    },
    destroyed() {
        this.steps = []
    },
    watch: {
  
    },
    mounted() {
        this.customRefresh();
    },
    methods: {
        exportContacts(workflow_id){
            GenericDataService.postData('/export/exportContactsFromWF?workflow_id=' + workflow_id).then((response) => {
                if(response.data.data.directDownload){
                    this.downloadUrl = this.api + response.data.data.directDownload;
                    this.showDownload = true;
                }
            })
        }, 
        getPercentage(substat, global){
            if(global == 0)
                return 0;

            let percent = ((substat * 100)/global).toFixed(2);
            if(percent == 0)
                return 0;
            else return percent

        },
        launchAction(action, workflow) {
            if(action.event && action.event == 'gotoWorkflow'){
                this.gotoWorkflow(workflow);
            } else if(action.action == 'delete'){
                this.deleteElement(action, [workflow.id])
            } else if(action.action == 'post'){
                GenericDataService.postData(action.url.replace('{{id}}', workflow.id)).then((response) => {
                    this.customRefresh();
                    this.$store.dispatch('ADD_SNACK', {
                        message : this.$t('successActionConfirmed'),
                    }).then((e) => {
                        this.$store.dispatch("REMOVE_SNACK")
                    });
                })
            } else {
                this.getForm(action.url.replace('{{id}}', workflow.id) , false, { event: 'customRefresh', returnFullObject: true })
            }
        },
        deleteElement(link, arrayIds){
            if(link.confirm){
                if(link.confirm.checkurl){
                    let linkToCheck = link.confirm.checkurl
                    this.getForm(linkToCheck+"?id="+arrayIds, arrayIds, link)
                } else {
                    let options = {
                        action: link.url,
                        ids: arrayIds
                    }
                    this.openConfirmModal(link, options, arrayIds)
                }
            }
        },
        //ouverture de la modale de confirmation de delete
        async openConfirmModal(link, options){
            if(await this.$refs.confirmDelete.open(this.$t(link.confirm.label), this.$t(link.confirm.message), options)) {
                this.customRefresh();
                if(options.action) {
                    this.$store.dispatch('ADD_SNACK', {
                        message : this.$t('successActionConfirmed'),
                    }).then((e) => {
                        this.$store.dispatch("REMOVE_SNACK")
                    });
                }
            }
        },
        gotoWorkflow(wf){
            this.getForm('/library/getForm?id='+(wf.id ? wf.id : wf)+'&formName=' + this.libType + '&step=["conception"]', false, { event: 'customRefresh', returnFullObject: true });  
        },
        openLibraryDialog() {
            GenericDataService.getData("/library/getFilters?libType=" + this.libType +'&params[operation_id]=' + this.operationId).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
        customRefresh() {
            GenericDataService.getData("/workflow/list?operation_id="+this.operationId).then((response) => {
                this.workflows = response.data.data.data
                this.actions = response.data.data.actions.single
                this.keyWF++
            }).catch((e) => {
                this.loading = false
            })
        },
        // openForm(){
        //     this.getForm('/operation/'+this.operationId+'/getFormPlaces');
        // },
        // editPlace(place){
        //     this.getForm('/operation/'+this.operationId+'/getFormPlaces?id='+place.id)
        // },
        // closeDialog(){
        //     this.itemToDelete = null
        //     this.dialogConfirm = false
        // },
        // removePlace(){
        //     GenericDataService.getData('/operation/'+this.operationId+'/deletePlace?id='+this.itemToDelete).then((response) => {
        //         this.$emit('refreshRetroplanningAndOpe')
        //         this.places = response.data.data
        //         this.dialogConfirm = false
        //         this.itemToDelete = null
        //     }).catch((e) => {
        //         this.loading = false
        //     })
        // },
        // getDeleteConfirmationItem(idItem){
        //     this.getForm('/operation/checkBeforeDeletePlace?place_id='+idItem+'&operation_id='+this.operationId);
        //     // this.itemToDelete = idItem
        //     // this.dialogConfirm = true
        // }
    },
}
</script>
<style lang="scss">
    .cardLink{
        .v-card--link:hover {
            background-color: rgba(0,0,0,0.05) !important;
        }
    }
    .cardOpe {
        margin-bottom:8px;
        border:1px solid #CCC;   
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .text-adress {
        .icon {
            position:absolute;
            top:15px;
            right:10px;
        }
    }
</style>
