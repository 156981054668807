<template>
  <div class="wrapper">
    <div :style="$route.name == 'WorkflowList' ? 'overflow: auto; height: calc(100vh - 225px)' : 'height:100%;'" class="contentWorkflow">
      <v-expand-transition>
        <router-view />
      </v-expand-transition>
    </div>
  </div>
</template>
<script>


export default {
  name:"Workflow",
  components: {

  },

  data() {
    return {

    }
  },
  methods: {

  },
  computed: {

  },
  mounted() {
  },

}
</script>
<style lang="scss">
  .wrapper {
    height:100%;
  }
</style>
