<template>
    <div>
        <div class="d-flex py-2">
            <span class="h2">3 - {{ $t('leadProcessing') }}</span>
            <v-spacer></v-spacer>
            <div class="d-flex">
                <!-- <v-select background-color="white" v-model="typesModel" :items="typeItems" :label="$t('leadType_s')" dense outlined hide-details clearable multiple></v-select>
                <v-select class="ml-2" background-color="white" v-model="universModel" :items="universItems" :label="$t('univers')" dense outlined hide-details clearable multiple></v-select> -->
                <v-select class="ml-2" v-if="operation.type === 'MARKETING'" background-color="white" v-model="periodModel" :items="periodItems" :label="$t('selectDateRange')" dense outlined hide-details clearable></v-select>
            </div>
        </div>
        <div v-if="statsLeadProcessing">
            <v-row dense>
                <v-col>
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <v-icon large>$user_plus_d</v-icon>
                        <h3 class="mt-4">{{statsLeadProcessing.TOTAL}}</h3>
                        <span class="label mt-3">{{ $t('leads') }}</span>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <v-icon large>$actions_d</v-icon>
                        <h3 class="mt-4">{{statsLeadProcessing.TO_DEAL}}</h3>
                        <span class="label mt-3">{{ $t('toDoLeads') }} ({{ $t('sufferingIncluded') }})</span>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <v-icon large>$pausecircle_d</v-icon>
                        <h3 class="mt-4">{{statsLeadProcessing.WAITING}}</h3>
                        <span class="label mt-3">{{ $t('waitingLead') }}</span>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <v-icon large>$thumbs_up_d</v-icon>
                        <h3 class="mt-4">{{statsLeadProcessing.SUCCESS}}</h3>
                        <span class="label mt-3">{{ $t('demandSuccess') }}</span>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <v-icon large>$thumbs_down_d</v-icon>
                        <h3 class="mt-4">{{statsLeadProcessing.FAIL}}</h3>
                        <span class="label mt-3">{{ $t('demandFail') }}</span>
                    </v-card>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="3">
                    <donutContactStatsComponent v-for="(graph, index) in donutGraphs" :data="graph" :filters="false" :key="'donutgraph_' + index + '_' + graphKey" class="mb-2" :ref="'graph_' + graph.label" />
                </v-col>
                <v-col cols="9">
                    <v-row class="mb-2">
                        <v-col class="col col-6 pb-0 pr-1" cols="6">
                            <barContactStatsComponent :data="barGraphs[0]" :filters="false" :key="'bargraph_0_' + graphKey" />
                        </v-col>
                        <v-col class="col col-6 pb-0 pl-1" cols="6">
                            <barContactStatsComponent :data="barGraphs[1]" :filters="false" :key="'bargraph_1_' + graphKey" />
                        </v-col>
                    </v-row>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">{{ $t('vendor') }}</th>
                                    <th class="text-left">{{ $t('sellingPoint') }}</th>
                                    <th class="text-left">{{ $t('leads') }}</th>
                                    <th class="text-left">{{ $t('rdv') }}</th>
                                    <th class="text-left">{{ $t('cameRdv') }}</th>
                                    <th class="text-left">{{ $t('notComeRdvs') }}</th>
                                    <th class="text-left">{{ $t('demandsuccess') }}</th>
                                    <th class="text-left">{{ $t('demandfail') }}</th>
                                    <th class="text-left">{{ $t('conversion') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in nbContactsByFilter" :key="item.name">
                                    <td>{{ item.fullName }}</td>
                                    <td>{{ item.entity }}</td>
                                    <td>{{ item.nbLeads }}</td>
                                    <td>{{ item.nbRdvs }}</td>
                                    <td>{{ item.nbCameRdvs }}</td>
                                    <td>{{ item.nbNotCameRdvs }}</td>
                                    <td>{{ item.nbSuccess }}</td>
                                    <td>{{ item.nbFails }}</td>
                                    <td>{{ item.conversion }}%</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
        <div style="min-height: 500px !important;" v-else>
            <Loader :flat="true" style="min-height: 500px !important;"></Loader>
        </div>
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import barContactStatsComponent from '@/components/dialogs/dialogStatsComponents/barContactStatsComponent';
import donutContactStatsComponent from '@/components/dialogs/dialogStatsComponents/donutContactStatsComponent';
import Loader from '@/components/Loader.vue';

export default {
    name: "OperationsDetailStats",
    props: ['operation'],
    components: { barContactStatsComponent, donutContactStatsComponent, Loader },
    data() {
        return {
            statsLeadProcessing: null,
            periodModel: null,
            periodItems: [
                { "value": "endMonth", "text": this.$t("endMonth") },
                { "value": "today", "text": this.$t("today") },
                { "value": "yesterday", "text": this.$t("yesterday") },
                { "value": "thisWeekFromMonday", "text": this.$t("thisWeekFromMonday") },
                { "value": "lastSevenDays", "text": this.$t("lastSevenDays") },
                { "value": "lastWeek", "text": this.$t("lastWeek") },
                { "value": "lastFourteenDays", "text": this.$t("lastFourteenDays") },
                { "value": "thisMonth", "text": this.$t("thisMonth") },
                { "value": "lastMonth", "text": this.$t("lastMonth") },
                { "value": "lastThirtyDays", "text": this.$t("lastThirtyDays") },
                { "value": "lastNinetyDays", "text": this.$t("lastNinetyDays") },
                { "value": "lastOneEightyDays", "text": this.$t("lastOneEightyDays") },
                { "value": "thisYear", "text": this.$t("thisYear") },
                { "value": "slidingYear", "text": this.$t("slidingYear") }
            ],
            typesModel: [],
            typeItems: [],
            universModel: [],
            universItems: [],
            donutGraphs: [
                {
                    "label": "sellsByUnivers",
                    "datas": {}
                },
                {
                    "label": "failsByReason",
                    "datas": {}
                }
            ],
            barGraphs: [
                {
                    "type": "HorizontalBarComponent",
                    "label": "conversionBtnTitle",
                    "backgroundColor": "#5F05FF",
                    "datas": [
                        { "label": "leads", "to_trad": true, "value": 0, "notFilterable": true },
                        { "label": "rdv", "to_trad": true, "value": 0, "notFilterable": true },
                        { "label": "cameRdv", "to_trad": true, "value": 0, "notFilterable": true },
                        { "label": "successfulLeads", "to_trad": true, "value": 0, "notFilterable": true }
                    ]
                },
                {
                    "type": "BarComponent",
                    "label": "vendorProcessing",
                    "datas": {
                        labels: [],
                        totrad: true,
                        notFilterable: true,
                        datasets: [
                            { label: 'suffering', backgroundColor: '#DC0000', data: []},
                            { label: 'todeal', backgroundColor: '#FFB56E', data: []},
                            { label: 'waiting', backgroundColor: '#3CD1FF', data: []},
                            { label: 'success', backgroundColor: '#1EA522', data: []},
                            { label: 'demandfail', backgroundColor: '#161459', data: []},
                        ]
                    }
                },
            ],
            nbContactsByFilter: [],
            graphKey: 0
        }
    },
    methods: {
        loadStats() {
            let periodUrl = this.periodModel ? ('&period=' + this.periodModel) : '';
            let typeUrl = this.typesModel.length ? ('&types[]=' + this.typesModel.join('&types[]=')) : '';
            let universUrl = this.universModel.length ? ('&univers[]=' + this.universModel.join('&univers[]=')) : '';

            GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/getFullStatsLeadProcessing?1' + periodUrl + typeUrl + universUrl).then((response) => {
                this.statsLeadProcessing = response.data.data;

                for (let univers in this.statsLeadProcessing.byUnivers)
                    this.statsLeadProcessing.byUnivers[univers] = {"value": this.statsLeadProcessing.byUnivers[univers], "label": univers, "notFilterable": true};

                for (let failReason in this.statsLeadProcessing.byFailReason)
                    this.statsLeadProcessing.byFailReason[failReason] = {"value": this.statsLeadProcessing.byFailReason[failReason], "label": failReason, "notFilterable": true};

                this.barGraphs[1].datas.labels = [];
                this.barGraphs[1].datas.datasets[0].data = [];
                this.barGraphs[1].datas.datasets[1].data = [];
                this.barGraphs[1].datas.datasets[2].data = [];
                this.barGraphs[1].datas.datasets[3].data = [];
                this.barGraphs[1].datas.datasets[4].data = [];
                for (let vendor in this.statsLeadProcessing.byVendor) {
                    this.barGraphs[1].datas.labels.push(this.statsLeadProcessing.byVendor[vendor].fullName);
                    this.barGraphs[1].datas.datasets[0].data.push(this.statsLeadProcessing.byVendor[vendor].data['SUFFERING']);
                    this.barGraphs[1].datas.datasets[1].data.push(this.statsLeadProcessing.byVendor[vendor].data['TO_DEAL']);
                    this.barGraphs[1].datas.datasets[2].data.push(this.statsLeadProcessing.byVendor[vendor].data['WAITING']);
                    this.barGraphs[1].datas.datasets[3].data.push(this.statsLeadProcessing.byVendor[vendor].data['SUCCESS']);
                    this.barGraphs[1].datas.datasets[4].data.push(this.statsLeadProcessing.byVendor[vendor].data['FAIL']);
                }
                
                let defaultDatas = [{label: "NC (%)", notFilterable: true, value: 100}];
                this.donutGraphs[0].datas = Object.keys(this.statsLeadProcessing.byUnivers).length ? this.statsLeadProcessing.byUnivers : defaultDatas;
                this.donutGraphs[1].datas = Object.keys(this.statsLeadProcessing.byFailReason).length ? this.statsLeadProcessing.byFailReason : defaultDatas;
                this.barGraphs[0].datas[0].value = this.statsLeadProcessing.TOTAL;
                this.barGraphs[0].datas[1].value = this.statsLeadProcessing.rdvs;
                this.barGraphs[0].datas[2].value = this.statsLeadProcessing.cameRdvs;
                this.barGraphs[0].datas[3].value = this.statsLeadProcessing.SUCCESS;
                this.nbContactsByFilter = this.statsLeadProcessing.topVendors;

                this.graphKey++;
            });
        }
    },
    created() {
        this.loadStats();
        GenericDataService.getData('/hard_value/getSelectList?type=demand_type_select').then((response) => {
            this.typeItems = response.data.data.map(function(type) { type.text = type.to_trad ? this.$t(type.text) : type.text; return type; }, this)
        });
        GenericDataService.getData('/custom_value/getSelectListForType?type=univers').then((response) => {
            this.universItems = response.data.data;
        });
    },
    watch: {
        periodModel () {
            this.loadStats();
        },
        typesModel () {
            this.loadStats();
        },
        universModel () {
            this.loadStats();
        },
    },
}
</script>

<style lang="scss">
    .elementStat {
        width: 100%;
        text-align: center;
        h3 {
            color: inherit !important;
            font-weight: 800;
            font-size: 30px;
            @media(-webkit-device-pixel-ratio: 1.25) {
                font-size: 30px;
                margin-top: 10px;
            }
            span {
                font-size: 15px;
                font-weight: 400;
            }
        }
        .label {
            display: block;
            font-size: 13px;
        }
    }
</style>